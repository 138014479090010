import React from "react";
import {
  Container,
  Typography,
  List,
  Grid,
  Link,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import GetAppIcon from "@mui/icons-material/GetApp";
import LeetCodeIcon from "@mui/icons-material/Code";
import GeeksforGeeksIcon from "@mui/icons-material/CodeOff";
import resume from "./resume.pdf";
import "./importantLinks.css";

const ImportantLinksContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  backgroundColor: "#f9f9f9", // Use a light background color
}));

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ImportantLink = styled(List)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const IconWrapper = styled("div")({
  marginRight: "0.5rem",
});

const CardLink = ({ title, href, icon }) => (
  <Grid item xs={12} md={6}>
    <Card className="card-link">
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <IconWrapper>{icon}</IconWrapper>
          </Grid>
          <Grid item xs={10}>
            <ImportantLink>
              <Link href={href} target="_blank" rel="noopener noreferrer">
                {title}
              </Link>
            </ImportantLink>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);


const DownloadResumeLink = () => (
  <IconButton href={resume} download>
    <GetAppIcon />
    <Typography variant="h6">Download Resume</Typography>
  </IconButton>
);

const ImportantLinks = () => {
  return (
    <StyledContainer>
      <ImportantLinksContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Important Links
          </Typography>
          <DownloadResumeLink />
        </div>
        <Grid container spacing={2}>
          <CardLink
            title="LinkedIn Profile"
            href="https://www.linkedin.com/in/thesunnysinha/"
            icon={<LinkedInIcon />}
          />
          <CardLink
            title="GitHub Profile"
            href="https://github.com/thesunnysinha/"
            icon={<GitHubIcon />}
          />
          <CardLink
            title="LeetCode Profile"
            href="https://leetcode.com/thesunnysinha/"
            icon={<LeetCodeIcon />}
          />
          <CardLink
            title="GeeksforGeeks Profile"
            href="https://auth.geeksforgeeks.org/user/thesunnysinha/practice"
            icon={<GeeksforGeeksIcon />}
          />
        </Grid>
      </ImportantLinksContainer>
    </StyledContainer>
  );
};

export default ImportantLinks;
