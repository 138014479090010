import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  marginBottom: "2rem",
});

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
});

const StyledCardHeader = styled(CardHeader)({
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const Experience = () => {
  return (
    <StyledContainer>
      <StyledTypography variant="h4">Experience</StyledTypography>
      <Grid container sx={{ marginTop: 4 }} spacing={4}>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader
              title="Auctopus Technologies Private Limited"
              subheader="Software Developer"
            />
            <StyledCardContent>
              <Typography variant="body1">
                • Lead developer driving innovation using Django, React, and
                ServiceNow, creating impactful features and resolving complex
                issues.
              </Typography>
              <Typography variant="body1">
                • Architected robust full-stack applications by seamlessly
                integrating Django APIs with React front-end and ServiceNow
                environment.
              </Typography>
              <Typography variant="body1">
                • Crafted captivating user interfaces with React, ensuring
                smooth API consumption and exceptional front-end experiences.
              </Typography>
              <Typography variant="body1">
                • Collaborated within the ServiceNow ecosystem to replicate
                React-driven user-friendly front-end solutions.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader
              title="RiDiv Technologies"
              subheader="Developer Intern"
            />
            <StyledCardContent>
              <Typography variant="body1">
                • Contributed to diverse tech projects, mastering Django,
                Docker, AWS, and Django Rest Framework.
              </Typography>
              <Typography variant="body1">
                • Enhanced application performance through Webpack-optimized
                frontend, delivering seamless user experiences
              </Typography>
              <Typography variant="body1">
                • Designed machine learning model for IPL score prediction,
                showcasing strong data analysis and modeling skills
              </Typography>
              <Typography variant="body1">
                • Developed engaging React-based frontend, elevating user
                interface and interaction quality
              </Typography>
              <Typography variant="body1">
                • Implemented CRM systems, improving customer relationship
                management and communication strategies
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader
              title="The Sparks Foundation"
              subheader="Data Science Engineer"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Worked as a Data Science Engineer at The Sparks Foundation for 2
                months. Developed a simple linear regression task involving 2
                variables. Predicted the percentage of an student based on the
                no. of study hours.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader
              title="JPMorgan Chase & Co."
              subheader="Software Engineering Virtual Experience"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Worked as a software engineer at JPMorgan Chase & Co. for 2
                month. Experienced the Interface with a stock price data feed,
                used JPMorgan Chase frameworks and tools and Display data
                visually for traders.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Experience;
