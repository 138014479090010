import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  marginBottom: "2rem",
});

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
});

const StyledCardHeader = styled(CardHeader)({
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const Education = () => {
  return (
    <StyledContainer>
      <StyledTypography variant="h4">Education</StyledTypography>
      <Grid container sx={{ marginTop: 4 }} spacing={4}>
        <Grid item xs={12} sm={4}>
          <StyledCard>
            <StyledCardHeader
              title="Maulana Abul Kalam Azad University of Technology, West Bengal"
              subheader="Bachelors of Technology in Computer Science Engineering"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed my bachelors degree in computer science from the
                Maulana Abul Kalam Azad University of Technology, West Bengal.
                Coursework included data structures, algorithms, software
                engineering, and databases.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard>
            <StyledCardHeader
              title="Raja Ram Sah College, Raxaul"
              subheader="Higher Secondary School"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed my high school from Raja Ram Sah College, Raxaul.
                Coursework included physics, chemistry and mathematics.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard>
            <StyledCardHeader
              title="Cambridge Public School, Raxaul"
              subheader="Secondary School"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed my secondary school from Cambridge Public School,
                Raxaul. Coursework included social science, mathematics,
                science, hindi and english.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Education;
