import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grow,
} from "@mui/material";
import { GetApp } from "@mui/icons-material";
import homeLogo from "../images/home-main.svg";
import myImg from "../images/avatar.svg";
import Type from "../Typewriter/Typewriter";
import anime from "animejs";
import "./css/Home.css";
import SocialMediaSection from "./SocialMediaSection ";
import IntroductionSection from "./IntroductionSection ";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import resume from "./static/sunny_resume.pdf";

const purpleStyle = { color: "purple" };

const GradientTypography = styled(Typography)`
  text-align: center;
  background: linear-gradient(to right, #f50057, #1da1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledGridItem = styled(Grid)`
  text-align: center;
  perspective: 1000px; /* Set perspective for 3D effect */
`;

const StyledImage = styled.img`
  max-width: 100%;
  transition: transform 0.5s; /* Smooth transition for transform property */

  &:hover {
    transform: rotateY(180deg); /* Flip image horizontally on hover */
  }
`;

const AnimatedSection = styled.div`
  opacity: ${(props) => (props.inView ? 1 : 0)};
  transform: translateY(${(props) => (props.inView ? "0" : "20px")});
  transition: opacity 0.5s, transform 0.5s;
`;

const DownloadCard = styled(Card)`
  && {
    background-color: #f0f0f0;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }
  }
`;

const DownloadSection = styled.div`
  display: flex;
  align-items: center;
`;

const HumorousTag = styled(Typography)`
  margin-left: 10px;
  font-style: italic;
  font-size: ${({ isMobile }) => (isMobile ? "1rem" : "1.2rem")};
`;

const Home = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });
  const [waveRef, waveInView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (waveInView) {
      const wave = document.querySelector(".wave");
      anime({
        targets: wave,
        translateX: [0, -10, 0],
        rotate: [0, 10, 0],
        duration: 2000,
        loop: true,
      });
    }
  }, [waveInView]);

  const handleDownload = () => {
    const url = resume;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "resume.pdf";
    anchor.click();
  };

  return (
    <Grid container sx={{ padding: 2 }} spacing={2}>
      <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
        <img src={homeLogo} alt="home pic" style={{ maxWidth: "100%" }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <AnimatedSection inView={waveInView}>
          <Typography
            variant="h4"
            style={{
              paddingBottom: 15,
              marginTop: "150px",
              textAlign: "center",
            }}
          >
            Hi There!{" "}
            <span role="img" className="wave" ref={waveRef}>
              👋🏻
            </span>
          </Typography>
        </AnimatedSection>
        <AnimatedSection inView={waveInView}>
          <GradientTypography variant="h4" gutterBottom>
            I'M <strong>SUNNY KUMAR SINHA</strong>
          </GradientTypography>
        </AnimatedSection>

        <AnimatedSection inView={waveInView}>
          <div
            style={{
              padding: 50,
              textAlign: "left",
              ...purpleStyle,
              marginBottom: "150px",
            }}
          >
            <Typography
              style={purpleStyle}
              sx={{ textAlign: "center" }}
              variant="h5"
              gutterBottom
            >
              <strong>
                <Type />
              </strong>
            </Typography>
          </div>
        </AnimatedSection>

        <Grow in={waveInView}>
          <DownloadCard sx={{ textAlign: "center" }}>
            <CardContent>
              <DownloadSection>
                <IconButton
                  color="primary"
                  aria-label="download resume"
                  onClick={handleDownload}
                  style={{ fontSize: "2rem" }}
                >
                  <GetApp />
                </IconButton>
                <HumorousTag>
                  Download my resume and hire me, I'm <strong>"write"</strong>{" "}
                  for the job! 😉
                </HumorousTag>
              </DownloadSection>
            </CardContent>
          </DownloadCard>
        </Grow>

      </Grid>

      <Grid item xs={12} md={8}>
        <IntroductionSection />
      </Grid>
      <StyledGridItem item xs={12} md={4}>
        <AnimatedSection inView={inView}>
          <StyledImage src={myImg} alt="sunny" ref={ref} />
        </AnimatedSection>
      </StyledGridItem>
      <SocialMediaSection />
    </Grid>
  );
};

export default Home;
