import React from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const purpleStyle = { color: "#6a1b9a" };

const skills = {
  programming: [
    {
      title: "Languages",
      items: ["Python", "JavaScript (Node.js)", "Java"],
    },
    {
      title: "Frameworks & Libraries",
      items: [
        "Django (including Django Rest Framework)",
        "Flask",
        "FastAPI",
        "React.js",
        "Redux for state management",
        "Material UI for sleek UI designs",
        "Django Channels for real-time applications",
      ],
    },
    {
      title: "Scripting",
      items: ["Bash scripting", "Python scripting"],
    },
  ],
  devOps: [
    {
      title: "Containers & Orchestration",
      items: ["Docker"],
    },
    {
      title: "Configuration Management",
      items: ["Ansible"],
    },
    {
      title: "CI/CD & Automation",
      items: ["GitHub Actions"],
    },
    {
      title: "Workflow Management",
      items: ["Airflow"],
    },
    {
      title: "Monitoring & Logging",
      items: [
        "Prometheus",
        "Grafana",
        "ELK Stack (Elasticsearch, Logstash, Kibana)",
        "Fluentd for log management",
        "SNMP Exporter for exporting SNMP metrics",
      ],
    },
  ],
  cloudPlatforms: [
    {
      title: "Cloud Providers",
      items: ["AWS (Amazon Web Services)", "Microsoft Azure", "Google Cloud Platform"],
    },
  ],
  databases: [
    {
      title: "Relational Databases",
      items: ["PostgreSQL", "MySQL", "SQLite"],
    },
    {
      title: "NoSQL Databases",
      items: ["MongoDB", "Redis"],
    },
    {
      title: "Connection Pooling",
      items: ["pgbouncer for PostgreSQL"],
    },
  ],
};

const renderSkills = (category) => {
  return category.map((skill) => (
    <Box key={skill.title} sx={{ mb: 2 }}>
      <Typography variant="h6" sx={purpleStyle}>
        {skill.title}
      </Typography>
      <List>
        {skill.items.map((item, index) => (
          <ListItem key={index} sx={{ py: 0 }}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Box>
  ));
};

const IntroductionSection = () => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ ...purpleStyle, mb: 2 }}>
          Introduction
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.6 }}>
         Hello there! 👋 I'm Sunny Kumar Sinha, a passionate software developer with a diverse skill set that spans programming languages, DevOps tools, cloud platforms, and database technologies. My expertise includes building scalable applications, implementing automation pipelines, and leveraging cloud services for efficient and reliable solutions.
        </Typography>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" sx={{ ...purpleStyle, mb: 2 }}>
              Programming
            </Typography>
            {renderSkills(skills.programming)}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" sx={{ ...purpleStyle, mb: 2 }}>
              DevOps
            </Typography>
            {renderSkills(skills.devOps)}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" sx={{ ...purpleStyle, mb: 2 }}>
              Cloud Platforms
            </Typography>
            {renderSkills(skills.cloudPlatforms)}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" sx={{ ...purpleStyle, mb: 2 }}>
              Databases
            </Typography>
            {renderSkills(skills.databases)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntroductionSection;
