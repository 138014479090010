import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  marginBottom: "2rem",
});

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
});

const StyledCardHeader = styled(CardHeader)({
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const Certifications = () => {
  return (
    <StyledContainer>
      <StyledTypography variant="h4">Certifications</StyledTypography>
      <Grid container sx={{ marginTop: 4 }} spacing={4}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title="Certification in React"
              subheader="Udemy"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed an online course on React.js from Udemy, covering
                topics such as component composition, state management, and
                server-side rendering.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title="Certification in Java"
              subheader="Oracle"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed a certification program on Java from Oracle, covering
                topics such as object-oriented programming, GUI development, and
                network programming.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title="Certification in Python"
              subheader="Coursera"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed a specialization on Python from Coursera, covering
                topics such as data structures, algorithms, and object-oriented
                programming.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardHeader
              title="Certification in Machine Learning"
              subheader="edX"
            />
            <StyledCardContent>
              <Typography variant="body1">
                Completed an online course on Machine Learning from edX,
                covering topics such as regression, classification, and
                clustering.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Certifications;
