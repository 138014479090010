const skillsList = [
  {
    icon: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
    label: "React",
  },
  {
    icon: "https://image.flaticon.com/icons/svg/1216/1216733.svg",
    label: "HTML",
  },
  { icon: "https://image.flaticon.com/icons/svg/732/732190.svg", label: "CSS" },
  {
    icon: "https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg",
    label: "Bootstrap",
  },
  {
    icon: "https://www.python.org/static/community_logos/python-logo-master-v3-TM.png",
    label: "Python",
  },
  {
    icon: "https://www.vectorlogo.zone/logos/djangoproject/djangoproject-icon.svg",
    label: "Django",
  },
  {
    icon: "https://www.vectorlogo.zone/logos/java/java-icon.svg",
    label: "Java",
  },
  {
    icon: "https://www.vectorlogo.zone/logos/java/java-icon.svg",
    label: "Rest API",
  },
  { icon: "https://www.vectorlogo.zone/logos/c/c-icon.svg", label: "C" },
  {
    icon: "https://www.vectorlogo.zone/logos/java/java-icon.svg",
    label: "SQL",
  },
  {
    icon: "https://www.vectorlogo.zone/logos/celeryproject/celeryproject-icon.svg",
    label: "Celery",
  },
  {
    icon: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Redis_Logo.svg",
    label: "Redis",
  },
  {
    icon: "https://channels.readthedocs.io/en/latest/_static/logo.png",
    label: "Django Channels",
  },
  {
    icon: "https://www.docker.com/sites/default/files/d8/2019-07/Moby-logo.png",
    label: "Docker",
  },
  {
    icon: "https://cdn.iconscout.com/icon/free/png-256/linux-2752241-2284906.png",
    label: "Linux",
  },
  {
    icon: "https://redux.js.org/img/redux.svg",
    label: "Redux",
  },
  {
    icon: "https://mui.com/static/logo_raw.svg",
    label: "Material-UI",
  },
  {
    icon: "https://www.djangoproject.com/m/img/logos/django-logo-negative.png",
    label: "Django Rest Framework",
  },
  {
    icon: "https://docs.ansible.com/ansible-tower/3.8.0/html_logo.png",
    label: "Ansible",
  },
  {
    icon: "https://www.postgresql.org/media/img/about/press/elephant.png",
    label: "PostgreSQL",
  },
  {
    icon: "https://flask.palletsprojects.com/en/2.1.x/_images/flask-logo.png",
    label: "Flask",
  },
  {
    icon: "https://aws.amazon.com/favicon.ico",
    label: "AWS",
  },
  {
    icon: "https://azure.microsoft.com/favicon.ico",
    label: "Azure",
  },
  {
    icon: "https://cloud.google.com/_static/images/cloud/icons/favicons/onecloud/super_cloud.png",
    label: "GCP",
  },
  {
    icon: "https://prometheus.io/assets/icon-192x192.png",
    label: "Prometheus",
  },
  {
    icon: "https://www.ossramblings.com/favicon.ico",
    label: "SNMP Exporter",
  },
  {
    icon: "https://www.ossramblings.com/favicon.ico",
    label: "Prometheus Alert Manager",
  },
];

export default skillsList;
