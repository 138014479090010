import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#3498db", // Match with the primary color used in Navbar
    },
    grey: {
      200: "#eeeeee",
      800: "#424242",
    },
  },
});

const Footer = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: theme.palette.primary.main, // Match with Navbar primary color
          color: theme.palette.common.white, // White text color
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body2" align="center">
            {"Copyright © "}
            {new Date().getFullYear()}
            {" Sunny Kumar Sinha"}
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
