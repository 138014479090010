import React from "react";
import { Container, Typography, Grid, Chip, styled } from "@mui/material";
import skillsList from "./skillsConfig";

const StyledSkillsContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  textAlign: "center",
});

const StyledGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  transition: "all 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const StyledChip = styled(Chip)({
  marginBottom: "1rem",
  marginRight: "1rem",
  maxWidth: "300px",
  borderRadius: "12px", // Rounded corners
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
  transition: "all 0.3s",
  "&:hover": {
    backgroundColor: "#e0e0e0", // Change background color on hover
  },
});

const Skills = () => {
  return (
    <StyledSkillsContainer>
      <Typography variant="h4" align="center" mb={4}>
        Skills
      </Typography>
      <Grid container spacing={2}>
        {skillsList.map((skill) => (
          <StyledGridItem item xs={12} sm={6} md={3} key={skill.label}>
            <StyledChip
              avatar={
                <img
                  src={skill.icon}
                  alt={skill.label}
                  style={{ width: "20px", height: "20px", marginRight: "5px" }}
                />
              }
              label={skill.label}
              variant="outlined"
            />
          </StyledGridItem>
        ))}
      </Grid>
    </StyledSkillsContainer>
  );
};

export default Skills;
