import React, { useState, useRef, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

const Nav = styled.nav`
  background: #2c3e50; /* Dark blue background */
  height: 80px; /* Increased height for better visibility */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 1.2rem; /* Larger font size */
  position: sticky;
  top: 0;
  z-index: 999;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #ecf0f1; /* Light gray text */
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #3498db; /* Blue hover color */
  }

  &.active {
    color: #3498db; /* Blue active color */
    font-weight: bold; /* Bold font for active link */
  }
`;

const Bars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: #ecf0f1; /* Light gray icon color */
    transition: all 0.3s ease;
    z-index: 1000; /* Higher z-index to stay above the mobile nav */

    &:hover {
      color: #3498db; /* Blue hover color for icon */
    }
  }
`;

const MobileNav = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    background: rgba(44, 62, 80, 0.5); /* Dark blue background with 50% transparency */
    backdrop-filter: blur(10px); /* Blur effect for better visibility */
    position: absolute;
    top: 80px; /* Adjusted to appear below the Nav */
    right: 0; /* Align to the right */
    width: 50%; /* Cover half the screen width */
    height: calc(100vh - 80px); /* Adjust height to cover the remaining screen below the Nav */
    z-index: 999;
    border-top-left-radius: 30px; /* Curved edges */
    border-bottom-left-radius: 30px; /* Curved edges */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
`;

const MobileNavLink = styled(Link)`
  color: #ecf0f1; /* Light gray text */
  text-decoration: none;
  padding: 1rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #3498db; /* Blue hover color */
  }

  &.active {
    color: #3498db; /* Blue active color */
    font-weight: bold; /* Bold font for active link */
  }
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileNavRef = useRef();

  const handleClickOutside = (event) => {
    if (mobileNavRef.current && !mobileNavRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <Nav>
        <NavMenu>
          <NavLink to="/" exact activeClassName="active">
            Home
          </NavLink>
          <NavLink to="/certifications" activeClassName="active">
            Certifications
          </NavLink>
          <NavLink to="/education" activeClassName="active">
            Education
          </NavLink>
          <NavLink to="/experience" activeClassName="active">
            Experience
          </NavLink>
          <NavLink to="/projects" activeClassName="active">
            Projects
          </NavLink>
          {/* <NavLink to="/skills" activeClassName="active">
            Skills
          </NavLink> */}
          <NavLink to="/importantlinks" activeClassName="active">
            Important Links
          </NavLink>
          <NavLink to="/contact" activeClassName="active">
            Contact
          </NavLink>
        </NavMenu>
        <Bars onClick={() => setIsMenuOpen(!isMenuOpen)} />
      </Nav>
      <MobileNav ref={mobileNavRef} isMenuOpen={isMenuOpen}>
        <MobileNavLink to="/" exact activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Home
        </MobileNavLink>
        <MobileNavLink to="/certifications" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Certifications
        </MobileNavLink>
        <MobileNavLink to="/education" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Education
        </MobileNavLink>
        <MobileNavLink to="/experience" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Experience
        </MobileNavLink>
        <MobileNavLink to="/projects" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Projects
        </MobileNavLink>
        {/* <MobileNavLink to="/skills" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Skills
        </MobileNavLink> */}
        <MobileNavLink to="/importantlinks" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Important Links
        </MobileNavLink>
        <MobileNavLink to="/contact" activeClassName="active" onClick={() => setIsMenuOpen(false)}>
          Contact
        </MobileNavLink>
      </MobileNav>
    </>
  );
};

export default Navbar;
