import React from "react";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ContactContainer = styled(Container)({
  paddingTop: 6,
  paddingBottom: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const AddressContainer = styled("div")({
  marginTop: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const PhoneContainer = styled("div")({
  marginTop: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const EmailContainer = styled("div")({
  marginTop: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const MapContainer = styled("iframe")({
  width: "100%",
  height: "400px",
  border: 0,
});

const Contact = () => {
  return (
    <StyledContainer>
      <ContactContainer>
        <Typography variant="h4">Contact</Typography>
        <AddressContainer>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">
            Sundarpur Road, Ward No 4, Raxaul, East Champaran, Bihar 845305
          </Typography>
        </AddressContainer>
        <PhoneContainer>
          <Typography variant="h6">Phone:</Typography>
          <Typography variant="body1">
            <a href="tel:+1-725-783-0012">(725) 783-0012</a>
          </Typography>
        </PhoneContainer>
        <EmailContainer>
          <Typography variant="h6">Email:</Typography>
          <Typography variant="body1">
            <a href="mailto:thesunnysinha@gmail.com">thesunnysinha@gmail.com</a>
          </Typography>
        </EmailContainer>
        <MapContainer
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118826.24603961696!2d84.8384739460011!3d26.98314818142153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f0a0a736dcf55d%3A0x9d34c6bb568fe6f8!2sRaxaul%2C%20Bihar%20845305%2C%20India!5e0!3m2!1sen!2sus!4v1645676110466!5m2!1sen!2sus"
          title="Google Maps"
          loading="lazy"
        />
      </ContactContainer>
    </StyledContainer>
  );
};

export default Contact;
