import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const SocialMediaSection = () => {
  return (
    <Grid item xs={12} sx={{ textAlign: "center", mt: 4 }}>
      <Typography variant="h4" sx={{ paddingBottom: 2 }}>
        Social Media
      </Typography>
      <Box
        className="social-icons"
        sx={{ display: "flex", justifyContent: "center", gap: 3 }}
      >
        <a
          href="https://github.com/thesunnysinha"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillGithub size={30} style={{ color: "black" }} />
        </a>
        <a
          href="https://www.linkedin.com/in/thesunnysinha/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn size={30} style={{ color: "#0077B5" }} />
        </a>
        <a
          href="https://twitter.com/thesunnysinha"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineTwitter size={30} style={{ color: "#1DA1F2" }} />
        </a>
        <a
          href="https://www.instagram.com/sunnyk.sinha/"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillInstagram size={30} style={{ color: "#E1306C" }} />
        </a>
      </Box>
    </Grid>
  );
};

export default SocialMediaSection;
