import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import Home from "./components/Home/Home";
import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Projects from "./components/Projects/Projects";
import Skills from "./components/Skills/Skills";
import Layout from "./components/UI/Layout/Layout";
import Certifications from "./components/Certifications/Certifications";
import Contact from "./components/Contact/Contact";
import ImportantLinks from "./components/ImportantLinks/ImportantLinks";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home/>} />
            <Route path="education" element={<Education />} />
            <Route path="experience" element={<Experience />} />
            <Route path="projects" element={<Projects />} />
            <Route path="skills" element={<Skills />} />
            <Route path="certifications" element={<Certifications />} />
            <Route path="contact" element={<Contact />} />
            <Route path="importantlinks" element={<ImportantLinks/>} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
