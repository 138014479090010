import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)({
  paddingTop: "6rem",
  paddingBottom: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  marginBottom: "2rem",
});

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
});

const StyledCardHeader = styled(CardHeader)({
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  maxHeight: "400px",
  overflowY: "auto",
  spacing: "2px",
});

const Projects = () => {
  return (
    <StyledContainer>
      <StyledTypography variant="h4">Projects</StyledTypography>
      <Grid container sx={{ marginTop: 4 }} spacing={4}>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader title="Andocs" subheader="Web Application" />
            <StyledCardContent>
              <Typography variant="body1" paragraph>
                🛍️ Andocs E-commerce Website
              </Typography>
              <Typography variant="body1" paragraph>
                🚀 A feature-rich e-commerce website developed using Django,
                Rest API, React Js, and Material UI.
              </Typography>
              <Typography variant="body1" paragraph>
                🔹 Highlights:
              </Typography>
              <Typography variant="body1" paragraph>
                Implemented an intelligent Product Recommendation System using
                Machine Learning, enhancing user engagement and boosting
                conversion rates.
              </Typography>
              <Typography variant="body1" paragraph>
                Developed the front-end with React Js and Material UI,
                delivering an intuitive and visually captivating user interface.
              </Typography>
              <Typography variant="body1" paragraph>
                Leveraged Django and Rest API for a robust back-end
                infrastructure, ensuring seamless data handling and
                client-server communication.
              </Typography>
              <Typography variant="body1" paragraph>
                🌟 Key Technologies:
              </Typography>
              <Typography variant="body1" paragraph>
                Django, Django Rest framework, React Js, Material UI, Machine
                Learning, Docker
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledCard>
            <StyledCardHeader
              title="Alien Invasion"
              subheader="Web Application"
            />
            <StyledCardContent>
              <Typography variant="body1" paragraph>
                🚀 Python Game Development Project: Alien Invasion 🛸 🎮
              </Typography>
              <Typography variant="body1" paragraph>
                Embark on a journey into the world of game development with my
                latest project inspired by Eric Matthes' "Python Crash Course."
                📘
              </Typography>
              <Typography variant="body1" paragraph>
                In this immersive experience, I brought the classic concept of
                an alien invasion to life through code, creativity, and
                problem-solving. 🛰️
              </Typography>
              <Typography variant="body1" paragraph>
                Project Overview:
              </Typography>
              <Typography variant="body1" paragraph>
                In collaboration with Eric Matthes' "Python Crash Course," I
                developed an exciting space-themed game called "Alien Invasion."
                The project showcases my proficiency in Python programming,
                object-oriented design, and graphical user interface
                development. 🌌
              </Typography>
              <Typography variant="body1" paragraph>
                Game Highlights:
              </Typography>
              <Typography variant="body1" paragraph>
                <Typography variant="body1">
                  ✨ Designed and coded a spaceship control mechanism for
                  players to navigate through the cosmos.
                </Typography>
                <Typography variant="body1">
                  ✨ Developed dynamic alien invaders that move across the
                  screen and engage in responsive behavior.
                </Typography>
                <Typography variant="body1">
                  ✨ Implemented shooting mechanics, allowing players to defend
                  against the alien onslaught and earn points.
                </Typography>
                <Typography variant="body1">
                  ✨ Designed vibrant graphics and animations to enhance the
                  immersive gameplay experience.
                </Typography>
                <Typography variant="body1">
                  ✨ Enhanced the game with engaging sound effects and
                  background music to create an interactive atmosphere.
                </Typography>
              </Typography>
              <Typography variant="body1" paragraph>
                Skills Demonstrated:
              </Typography>
              <Typography variant="body1" paragraph>
                <Typography variant="body1">🔹 Python Programming</Typography>
                <Typography variant="body1">
                  🔹 Object-Oriented Design
                </Typography>
                <Typography variant="body1">
                  🔹 User Interface Development
                </Typography>
                <Typography variant="body1">
                  🔹 Game Physics and Animation
                </Typography>
                <Typography variant="body1">
                  🔹 Problem-Solving and Logic Implementation
                </Typography>
                <Typography variant="body1">🔹 Creative Game Design</Typography>
              </Typography>
              <Typography variant="body1" paragraph>
                🎯 Impact:
              </Typography>
              <Typography variant="body1" paragraph>
                Through this project, I honed my coding skills while applying
                theoretical concepts to create a functional and entertaining
                game. The project not only showcases my technical expertise but
                also highlights my dedication to mastering new programming
                challenges.
              </Typography>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Projects;
