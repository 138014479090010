import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { CssBaseline } from "@mui/material";
import image from "../../images/background.jpg";

const LayoutWrapper = styled.div`
  background: url(${image}) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Quantico", sans-serif;
`;

const PageWrapper = styled.div`
  position: relative;
  .page-enter {
    opacity: 0;
  }
  .page-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
  .page-exit {
    opacity: 1;
  }
  .page-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`;

const Layout = () => {
  return (
    <>
      <LayoutWrapper>
        <CssBaseline />
        <Navbar />
        <TransitionGroup>
          <CSSTransition classNames="page" timeout={300}>
            <PageWrapper>
              <Outlet />
            </PageWrapper>
          </CSSTransition>
        </TransitionGroup>
      </LayoutWrapper>
      <Footer />
    </>
  );
};

export default Layout;
